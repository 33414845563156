import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./style.css";

function MatchesDetails(props) {
  const [data, setData] = useState({});

  var nr = 0;

  async function getData() {
    const url = "https://r44ks00ccgo8so4w0kwg8kgo.apis.rivalsf.com/v2/draft/elo?size=150";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="fag-match-page section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="match-page-right">
                <div className="single-match-widget">
                  <h3>Top Player Ranking</h3>
                  <div className="match-widget-inn table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Player</th>
                          <th>Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.items &&
                          data.items.map((item) => {
                            nr++;

                            return (
                              <tr>
                                <td>{nr}</td>
                                <td>
                                  <p>{item.User.Player.Codename}</p>
                                </td>
                                <td>{item.elo}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MatchesDetails;
