import React from "react";
import PageTitle from "../components/PageTitle";
import MatchesDetails from "../components/MatchesDetails";

const ELOPage = () => {
  return (
    <>
      <PageTitle Title={<>ELO Overview</>} />
      <MatchesDetails />
    </>
  );
};
export default ELOPage;
