import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ELOPage from "../../pages";

const AllRoute = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<ELOPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AllRoute;
